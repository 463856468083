import React, { useContext, useEffect, useState } from 'react'
import { CaretDownOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Row, DatePicker, TimeRangePickerProps, ConfigProvider } from 'antd'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import ja from 'antd/locale/ja_JP'
import moment from 'moment'
import { MediaItem } from '../../../types'
import { AppContext } from '../../../hooks/AppContext'

type Props = {
  mediaList: MediaItem[]
  setMediaList: any
  setMediaFilteredList: any
  schedule: any
  setSchedule: any
  imgTypeSelected: any
  setImgTypeSelected: any
}

const { RangePicker }: any = DatePicker

const dateFormat = 'YYYY/MM/DD'

const img_type = [
  {
    type: 0,
    name: 'すべて種類'
  },
  {
    type: 3,
    name: 'テキストで生成'
  },
  {
    type: 4,
    name: '背景・人物の全体イラスト化'
  },
  {
    type: 4,
    name: '人物のみのイラスト化',
    subType: 5
  }
]

const MediaFilter = (props: Props) => {
  const {
    mediaList,
    setMediaFilteredList,
    schedule,
    setSchedule,
    imgTypeSelected,
    setImgTypeSelected
  } = props
  const { checkEmailLimitedElements } = useContext(AppContext)

  const [imgTypes, setImgTypes] = useState(img_type)
  const [imgTypeItems, setImgTypeItems] = useState<ItemType[]>()

  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dates) {
      setSchedule({
        startTime: new Date(dateStrings[0]),
        endTime: new Date(dateStrings[1])
      })
    }
  }

  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: 'Today', value: [dayjs(), dayjs()] },
    { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] }
  ]

  const handleData = (index: number) => {
    setImgTypeSelected(imgTypes[index])
  }

  const handleFilter = () => {
    let newDt = mediaList.filter((item: any) => {
      const createdAt = moment(item.created_at)
      const startDate = moment(schedule.startTime)
      const endDate = moment(schedule.endTime)

      if (imgTypeSelected.type == 0) return createdAt.isBetween(startDate, endDate, 'days', '[]')

      if (imgTypeSelected.hasOwnProperty('subType')) {
        return (
          item?.type_generate_media == imgTypeSelected.type &&
          createdAt.isBetween(startDate, endDate, 'days', '[]') &&
          item?.type_img2img == imgTypeSelected.subType
        )
      } else {
        return (
          item.type_img2img != imgTypes[3].subType &&
          item?.type_generate_media == imgTypeSelected.type &&
          createdAt.isBetween(startDate, endDate, 'days', '[]')
        )
      }
    })
    console.log('newDt::', newDt)
    setMediaFilteredList(newDt)
  }

  useEffect(() => {
    const items = imgTypes.map((item: any, index: number) => {
      const a: ItemType = {
        label: <p onClick={() => handleData(index)}>{item.name}</p>,
        key: index
      }
      return a
    })
    setImgTypeItems(items)
  }, [imgTypes])

  return (
    <Col>
      <Row
        style={{
          height: 130,
          width: '100%',
          justifyContent: 'space-around',
          alignItems: 'center',
          marginTop: 40,
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: '#b2b2b2',
          borderRadius: 5
        }}
      >
        {!checkEmailLimitedElements() && (
          <Row>
            <Dropdown menu={{ items: imgTypeItems }}>
              <Button
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  display: 'flex',
                  width: 250
                }}
              >
                {imgTypeSelected.name}
                <CaretDownOutlined style={{ fontSize: 20, color: '#878787' }} />
              </Button>
            </Dropdown>
          </Row>
        )}

        <Row>
          <ConfigProvider locale={ja}>
            <RangePicker
              value={[dayjs(schedule.startTime), dayjs(schedule.endTime)]}
              // presets={rangePresets}
              onChange={onRangeChange}
              format={dateFormat}
              showToday={true}
            />
          </ConfigProvider>
        </Row>
        <Row>
          <Button onClick={handleFilter} type='primary' style={{ width: 100, fontWeight: 'bold' }}>
            検索
          </Button>
        </Row>
      </Row>
    </Col>
  )
}

export default MediaFilter
