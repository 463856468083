import { Button, Checkbox, Col, Drawer, Flex, Modal, Row, Slider, Space, Typography } from 'antd'
import React, { useState, useRef, useContext, TouchEventHandler, useEffect } from 'react'
import { AppContext } from '../../../hooks/AppContext'
import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import CommonAPIs from '../../../controller/API/CommonAPIs'
import { GenerateImgType } from '../../../types'
import Constant from '../../../controller/Constant'

type Props = {
  isVisibleInPaint: boolean
  closeInPaint: () => void
  mediaSelectedInfo: GenerateImgType
  setMediaSelectedInfo?: any
  setMediaProcessingJobID: any
  backgroundEnable?: boolean
  isImg2Img?: boolean
  setMediaSelectedList?: any
  mediaSelectedList?: GenerateImgType[]
  isMediaListScreen?: boolean
}

const InpaintScreen = (props: Props) => {
  const {
    isVisibleInPaint,
    closeInPaint,
    mediaSelectedInfo,
    setMediaSelectedInfo,
    setMediaProcessingJobID,
    backgroundEnable = true,
    isImg2Img = true,
    setMediaSelectedList,
    mediaSelectedList,
    isMediaListScreen = false
  } = props
  const { showWaitGenerate, showErrorAlert, isMobile, setIsSpinning } = useContext(AppContext)
  const access_token = localStorage.getItem('token') ?? null
  const navigate = useNavigate()
  const location = useLocation()
  const canvasRef = useRef(null)
  const canvasDownloadRef = useRef(null)
  const [drawing, setDrawing] = useState(false)
  const [lineWidth, setLineWidth] = useState(22)
  const [renderType, setRenderType] = useState(Constant.renderType.hand)
  const [imageWidth, setImageWidth] = useState(0)
  const [imageHeight, setImageHeight] = useState(0)

  const onResetPaint = () => {
    const canvas: any = canvasRef.current
    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height)
  }

  const onResetAndClosePaint = () => {
    closeInPaint()
    const canvas: any = canvasRef.current
    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height)
  }

  const handleMouseDown = (event: any) => {
    setDrawing(true)
    const canvas: any = canvasRef.current
    const rect = canvas.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top
    const ctx = canvas.getContext('2d')
    ctx.beginPath()
    ctx.moveTo(x, y)
  }

  const handleMouseMove = (event: any) => {
    if (!drawing) return
    const canvas: any = canvasRef.current
    const rect = canvas.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top
    const ctx = canvas.getContext('2d')
    ctx.lineTo(x, y)
    ctx.strokeStyle = 'white'
    ctx.lineWidth = lineWidth
    ctx.lineCap = 'round'
    ctx.lineJoin = 'round' // Set the line join to round for a rounded stroke
    ctx.stroke()
  }

  const handleMouseUp = () => {
    setDrawing(false)
  }

  const handleTouchDown = (event: any) => {
    console.log('event::', event)
    //get x and y position of the touch event
    // const x = event.touches[0].clientX
    // const y = event.touches[0].clientY
    setDrawing(true)
    const canvas: any = canvasRef.current
    const rect = canvas.getBoundingClientRect()
    const x = event.touches[0].clientX - rect.left
    const y = event.touches[0].clientY - rect.top
    const ctx = canvas.getContext('2d')
    ctx.beginPath()
    ctx.moveTo(x, y)
  }

  const handleTouchMove = (event: any) => {
    console.log('Touch move::', event)
    if (!drawing) return
    // Get the position of the touch event relative to the canvas
    const canvas: any = canvasRef.current
    const rect = canvas.getBoundingClientRect()
    const x = event.touches[0].clientX - rect.left
    const y = event.touches[0].clientY - rect.top
    const ctx = canvas.getContext('2d')
    ctx.lineTo(x, y)
    ctx.strokeStyle = 'white'
    ctx.lineWidth = lineWidth
    ctx.lineCap = 'round'
    ctx.lineJoin = 'round' // Set the line join to round for a rounded stroke
    ctx.stroke()
  }

  const handleTouchUp = () => {
    setDrawing(false)
  }

  const handleConvertBase64ToFile = (base64: string) => {
    const filename = 'image.jpg'
    const mimeType = 'image/jpeg'
    // Split the base64 string to get the data part
    const dataPart = base64.split(',')[1]

    // Convert the base64 data to a Blob
    const blob = atob(dataPart)
    const arrayBuffer = new ArrayBuffer(blob.length)
    const uint8Array = new Uint8Array(arrayBuffer)
    for (let i = 0; i < blob.length; i++) {
      uint8Array[i] = blob.charCodeAt(i)
    }

    // Create a Blob
    const blobObject = new Blob([uint8Array], { type: mimeType })

    // Create a File
    const file = new File([blobObject], filename, { type: mimeType })

    return file
  }

  const getBase64InPainImg = () => {
    const canvas: any = canvasRef.current
    const ctx = canvas.getContext('2d')

    // Create a copy of the canvas
    const canvasCopy = document.createElement('canvas')
    const ctxCopy: any = canvasCopy.getContext('2d')
    canvasCopy.width = canvas.width
    canvasCopy.height = canvas.height
    ctxCopy.drawImage(canvas, 0, 0)

    // Clear the canvas and display the original image
    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = 'black'
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    const link = document.createElement('a')
    link.href = canvas.toDataURL()
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(canvasCopy, 0, 0)
    return link.href
  }

  const handleReRenderHand = async () => {
    const base64 = getBase64InPainImg()
    console.log('base64::', base64)
    let paintImg: any = ''
    if (base64) {
      paintImg = handleConvertBase64ToFile(base64)
    }
    if (paintImg) {
      if (renderType == Constant.renderType.hand) {
        if (isImg2Img) {
          console.log('Render fix hand img2img')
          await CommonAPIs.rerenderMask(access_token, paintImg, mediaSelectedInfo.id)
            .then((res) => {
              console.log('res::', res)
              showWaitGenerate()
              if (isMediaListScreen) {
                const newDt = [...(mediaSelectedList as GenerateImgType[])]
                setMediaSelectedList([...newDt, mediaSelectedInfo])
              } else {
                setMediaSelectedInfo(mediaSelectedInfo)
              }
              setMediaProcessingJobID(res?.job_id)
              onResetAndClosePaint()
            })
            .catch((err) => showErrorAlert(err))
        } else {
          console.log('Render fix hand txt2img')
          await CommonAPIs.fixHandTxt2Img(access_token, paintImg, mediaSelectedInfo.id)
            .then((res) => {
              console.log('res::', res)
              showWaitGenerate()
              if (isMediaListScreen) {
                const newDt = [...(mediaSelectedList as GenerateImgType[])]
                setMediaSelectedList([...newDt, mediaSelectedInfo])
              } else {
                setMediaSelectedInfo(mediaSelectedInfo)
              }
              setMediaProcessingJobID(res?.job_id)
              onResetAndClosePaint()
            })
            .catch((err) => showErrorAlert(err))
        }
      } else {
        console.log('Render fix background')
        await CommonAPIs.fixBackgroundImg2Img(access_token, paintImg, mediaSelectedInfo.id)
          .then((res) => {
            console.log('res::', res)
            showWaitGenerate()
            if (isMediaListScreen) {
              const newDt = [...(mediaSelectedList as GenerateImgType[])]
              setMediaSelectedList([...newDt, mediaSelectedInfo])
            } else {
              setMediaSelectedInfo(mediaSelectedInfo)
            }
            setMediaProcessingJobID(res?.job_id)
            onResetAndClosePaint()
          })
          .catch((err) => showErrorAlert(err))
      }
    }
  }

  useEffect(() => {
    const urlImage = mediaSelectedInfo.url
    const img = new Image()
    img.onload = () => {
      setImageHeight(img.height)
      setImageWidth(img.width)
    }
    img.src = urlImage
  }, [])

  return (
    <Modal
      open={isVisibleInPaint}
      onCancel={onResetAndClosePaint}
      footer={null}
      width={1000}
      centered
      style={{ marginTop: 30, marginBottom: 30 }}
    >
      <Flex vertical align='center'>
        <h4 style={{ textAlign: 'center', marginTop: 0 }}>修正したい範囲を塗ってください</h4>
        <Flex
          align='center'
          style={{
            width: imageWidth,
            height: imageHeight,
            background: 'white'
          }}
        >
          <img
            src={mediaSelectedInfo.url}
            alt=''
            style={{
              position: 'absolute',
              zIndex: 999,
              width: imageWidth,
              height: imageHeight,

              objectFit: 'contain'
            }}
          />
          {mediaSelectedInfo.url && (
            <canvas
              ref={canvasRef}
              width={imageWidth}
              height={imageHeight}
              onTouchStart={handleTouchDown}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchUp}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              style={{
                position: 'absolute',
                zIndex: 999,
                cursor: 'grab',
                aspectRatio: 3 / 4
              }}
            />
          )}
        </Flex>
        <Flex vertical align='center'>
          {mediaSelectedInfo.url && (
            <Flex align='center' style={{ marginBottom: 20, marginTop: 20 }}>
              <div style={{ marginRight: 20 }}>ブラシサイズ</div>
              <Slider
                defaultValue={lineWidth}
                onChange={setLineWidth}
                max={50}
                min={5}
                style={{ width: isMobile ? 200 : 400 }}
              />
            </Flex>
          )}
          <Col style={{ marginBottom: 25 }}>
            <Typography style={{ marginBottom: 8 }}>修正したい部分</Typography>
            <Row>
              <Checkbox
                checked={renderType == Constant.renderType.hand}
                onClick={() => setRenderType(Constant.renderType.hand)}
                style={{ marginRight: 10 }}
              >
                手を修正する
              </Checkbox>
              {isImg2Img && (
                <Checkbox
                  checked={renderType == Constant.renderType.background}
                  onClick={() => setRenderType(Constant.renderType.background)}
                  style={{ marginRight: 10 }}
                >
                  境界線を削除する
                </Checkbox>
              )}
            </Row>
          </Col>
          <Button
            onClick={handleReRenderHand}
            style={{
              alignItems: 'center',
              marginBottom: 15,
              borderColor: 'black',
              color: 'black',
              width: 190
            }}
          >
            再生成する
          </Button>
          <Button
            onClick={onResetPaint}
            style={{
              alignItems: 'center',
              marginBottom: 15,
              borderColor: 'black',
              color: 'black',
              width: 190
            }}
          >
            リセット
          </Button>
          <Button
            onClick={closeInPaint}
            style={{
              alignItems: 'center',
              backgroundColor: 'black',
              color: 'white',
              width: 190
            }}
          >
            キャンセル
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default InpaintScreen
