import { CaretDownOutlined } from '@ant-design/icons'
import { Col, Layout, Row, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'

type Props = {}

const ProfileScreen = (props: Props) => {
  const navigate = useNavigate()

  const rowStyle = {
    background: 'white',
    height: 35,
    marginBottom: 18,
    alignItems: 'center'
  }
  const valueStyle = {
    flex: 0.8,
    background: '#BAB8B8',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 20,
    borderRadius: '5px',
    overflow: 'hidden',
    marginLeft: 16
  }

  const showTxtToImgScreen = () => {
    navigate('/txt2img')
  }

  return (
    <Layout style={{ background: 'white' }}>
      <h1 className='title'>テキスト入力で画像生成</h1>
      {/* <Row style={{ justifyContent: 'flex-end', marginTop: 30 }}>
        <Button onClick={showTxtToImgScreen}>AI画像一覧</Button>
      </Row> */}
      <Col>
        <Row style={{ ...rowStyle, marginTop: 50 }}>
          <Typography style={{ flex: 0.2 }}>名前</Typography>
          <Row style={valueStyle}>Mimi</Row>
        </Row>
      </Col>
      <Col>
        <Row style={rowStyle}>
          <Typography style={{ flex: 0.2 }}>メールアドレス</Typography>
          <Row style={valueStyle}>Abc&gmail.com</Row>
        </Row>
      </Col>
      <Col>
        <Row style={rowStyle}>
          <Typography style={{ flex: 0.2 }}>テキスト用チェックポイント</Typography>
          <Row style={{ ...valueStyle, paddingRight: 12 }}>
            <Typography style={{ flex: 1 }}>Dark_Shushi</Typography>
            <CaretDownOutlined />
          </Row>
        </Row>
      </Col>
      <Col>
        <Row style={rowStyle}>
          <Typography style={{ flex: 0.2 }}>画像アップ用チェックポイント</Typography>
          <Row style={{ ...valueStyle, paddingRight: 12 }}>
            <Typography style={{ flex: 1 }}>Dark_Shushi</Typography>
            <CaretDownOutlined />
          </Row>
        </Row>
      </Col>
    </Layout>
  )
}

export default ProfileScreen
