import { createContext, useEffect, useState } from 'react'
import { notification } from 'antd'
import type { NotificationPlacement } from 'antd/es/notification/interface'
import Constant from '../controller/Constant'
import { CheckCircleOutlined } from '@ant-design/icons'
interface contextProps {
  isSpinning: boolean
  setIsSpinning: (value: boolean) => void
  menuSelected: number
  setMenuSelected: (value: number) => void
  showErrorAlert: (value: string) => void
  contextHolder: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  progress: number
  setProgress: (value: number) => void
  screenName: string
  setScreenName: (value: string) => void
  descriptionAmount: number
  setDescriptionAmount: (value: number) => void
  isMobile: boolean
  showWaitGenerate: () => void
  showGenerateCompleted: () => void
  getEmailLimitedCheckpointStorage: () => boolean | string
  checkDisableEmailLimited: () => boolean
  checkEmailLimitedElements: () => boolean
  accountLimit1: string
  accountLimit2: string
  accountLimit3: string
}

export const AppContext = createContext({} as contextProps)

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSpinning, setIsSpinning] = useState<boolean>(false)
  const [menuSelected, setMenuSelected] = useState<number>(0)
  const [api, contextHolder] = notification.useNotification()
  const [screenName, setScreenName] = useState<string>('')
  const [progress, setProgress] = useState<number>(0)
  const [descriptionAmount, setDescriptionAmount] = useState<number>(1)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [isMobile, setIsMobile] = useState<boolean>(false)

  const emailLimitedCheckpointLowerCase =
    localStorage.getItem('accountLimitedCheckpoint')?.toLowerCase().trim() ?? ''
  const accountLimit1 = Constant.emailLimitCheckpoint.account1.toLowerCase().trim()
  const accountLimit2 = Constant.emailLimitCheckpoint.account2.toLowerCase().trim()
  const accountLimit3 = Constant.emailLimitCheckpoint.account3.toLowerCase().trim()

  const showErrorAlert = (errorMsg: any, placement: NotificationPlacement = 'topRight') => {
    api.error({
      message: (
        <AppContext.Consumer>
          {() => `${errorMsg?.response?.data?.message ?? errorMsg?.message}`}
        </AppContext.Consumer>
      ),
      placement
    })
  }

  const showWaitGenerate = (placement: NotificationPlacement = 'top') => {
    notification.open({
      message: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CheckCircleOutlined style={{ fontSize: 30, color: '#2CD003', marginRight: 20 }} />
          <div>{`画像を生成しています。AIサーバー状況にお時間がかかる場合があります。後ほどAI画像一覧で確認してください。`}</div>
        </div>
      ),
      placement,
      style: {
        whiteSpace: 'pre-line',
        zIndex: 999,
        width: 520,
        paddingTop: 10,
        paddingBottom: 0
      },
      closeIcon: false
    })
  }

  const showGenerateCompleted = (placement: NotificationPlacement = 'top') => {
    notification.open({
      message: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CheckCircleOutlined style={{ fontSize: 30, color: '#2CD003', marginRight: 20 }} />
          <div>{`画像の生成が完了しました。AI画像一覧で確認してください。`}</div>
        </div>
      ),
      placement,
      style: {
        whiteSpace: 'pre-line',
        zIndex: 999,
        width: 520,
        paddingTop: 10,
        paddingBottom: 0
      },
      closeIcon: false
    })
  }

  const getEmailLimitedCheckpointStorage = (): string | boolean => {
    if (emailLimitedCheckpointLowerCase == accountLimit1) {
      return accountLimit1
    }
    if (emailLimitedCheckpointLowerCase == accountLimit2) {
      return accountLimit2
    }
    if (emailLimitedCheckpointLowerCase == accountLimit3) {
      return accountLimit3
    }
    return false
  }

  const checkDisableEmailLimited = () => {
    if (getEmailLimitedCheckpointStorage() && getEmailLimitedCheckpointStorage() != accountLimit3) {
      return true
    }
    return false
  }

  const checkEmailLimitedElements = () => {
    if (getEmailLimitedCheckpointStorage() == accountLimit3) {
      return true
    }
    return false
  }

  let limitMinutes = 0
  if (screenName) {
    switch (screenName) {
      case Constant.nameScreen.txt2img:
        limitMinutes = 4 * descriptionAmount
        break
      case Constant.nameScreen.img2img:
      case Constant.nameScreen.photoLibrary:
        limitMinutes = 4
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (screenName) {
      const intervalId = setInterval(() => {
        setProgress((prevProgress) => {
          let newProgress = prevProgress >= 90 ? prevProgress : prevProgress + 1
          return newProgress
        })
      }, (limitMinutes * 60 * 1000) / 90)

      if ((!isSpinning && screenName) || progress == 100) {
        setScreenName('')
        setProgress(0)
        clearInterval(intervalId)
      }

      return () => clearInterval(intervalId)
    }
  }, [screenName, isSpinning])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setIsMobile(windowWidth <= 750)
  }, [windowWidth])

  return (
    <AppContext.Provider
      value={{
        isSpinning,
        setIsSpinning,
        menuSelected,
        setMenuSelected,
        showErrorAlert,
        contextHolder,
        progress,
        setProgress,
        screenName,
        setScreenName,
        descriptionAmount,
        setDescriptionAmount,
        isMobile,
        showWaitGenerate,
        showGenerateCompleted,
        getEmailLimitedCheckpointStorage,
        checkDisableEmailLimited,
        checkEmailLimitedElements,
        accountLimit1,
        accountLimit2,
        accountLimit3
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
