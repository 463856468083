import { Col, Layout, Row, Typography } from 'antd'
import { useContext, useEffect, useState } from 'react'
import ImageItem from './components/ImageItem'
import { AppContext } from '../../hooks/AppContext'
import MediaFilter from './components/MediaFilter'
import CommonAPIs from '../../controller/API/CommonAPIs'
import moment from 'moment'
import { GenerateImgType, MediaItem, MediaProcessing } from '../../types'
import Constant from '../../controller/Constant'

const { Text } = Typography

const PhotoLibraryScreen = () => {
  const token = localStorage.getItem('token')
  const { isSpinning, setIsSpinning, showErrorAlert, setMenuSelected } = useContext(AppContext)
  const [mediaList, setMediaList] = useState<MediaItem[]>([])
  const [mediaFilteredList, setMediaFilteredList] = useState<MediaItem[]>([])
  const [schedule, setSchedule] = useState<{
    startTime: Date | string
    endTime: Date | string
  }>({
    startTime: moment().subtract(6, 'days').toDate(),
    endTime: new Date()
  })
  const [imgTypeSelected, setImgTypeSelected] = useState({
    type: 0,
    name: 'すべて種類'
  })
  const [mediaProcessingList, setMediaProcessingList] = useState<MediaProcessing[]>([])
  const [mediaSelectedList, setMediaSelectedList] = useState<GenerateImgType[]>([])

  console.log('mediaProcessingList::', mediaProcessingList)

  const getMedias = () => {
    setIsSpinning(true)
    CommonAPIs.getMedias(token)
      .then((res) => {
        console.log(res)
        setMediaList(res)
        let newDt = res.filter((item: any) => {
          const createdAt = moment(item.created_at)
          const startDate = moment(schedule.startTime)
          const endDate = moment(schedule.endTime)

          return createdAt.isBetween(startDate, endDate, 'days', '[]')
        })
        setMediaFilteredList(newDt)
      })
      .finally(() => setIsSpinning(false))
  }

  const getMediasProcessing = () => {
    if (token == null || token == undefined || token == '') {
      return
    }
    const mediaProcessingStorageAmount = localStorage.getItem('mediaProcessingAmount') ?? 0
    CommonAPIs.getMediasProcessing(token)
      .then((res) => {
        console.log('mediaProcessingStorageAmount::', mediaProcessingStorageAmount)
        console.log('res.length::', res.length)
        if (mediaProcessingStorageAmount && Number(mediaProcessingStorageAmount) > res?.length) {
          getMedias()
        }
        console.log('res::', res)
        setMediaProcessingList(res)
        localStorage.setItem('mediaProcessingAmount', res?.length)
      })
      .catch((err) => showErrorAlert(err))
  }

  const getDescriptions = (descripotions: string[] | undefined) => {
    if (descripotions == null || descripotions == undefined) {
      return ''
    }
    let description = ''
    descripotions.forEach((item, index) => {
      description += item
      if (index !== descripotions.length - 1) {
        description += ', '
      }
    })
    return description
  }

  useEffect(() => {
    setMenuSelected(3)
    getMedias()
    getMediasProcessing()
  }, [])

  useEffect(() => {
    const timerId = setInterval(() => {
      getMediasProcessing()
    }, 15000)

    if (mediaProcessingList?.length <= 0) {
      clearInterval(timerId)
    }

    return () => {
      console.log('clearrrr')
      clearInterval(timerId)
    }
  }, [mediaProcessingList, mediaSelectedList])

  return (
    <Layout style={{ background: 'white', paddingBottom: 40 }}>
      <h1 className='title'>画像生成履歴一覧</h1>
      <MediaFilter
        mediaList={mediaList}
        setMediaList={setMediaList}
        setMediaFilteredList={setMediaFilteredList}
        schedule={schedule}
        setSchedule={setSchedule}
        imgTypeSelected={imgTypeSelected}
        setImgTypeSelected={setImgTypeSelected}
      />
      <Col>
        <Row
          style={{
            background: '#5AAAFF',
            minHeight: 36,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 40,
            textAlign: 'center'
          }}
        >
          <Row style={{ flex: 1 }}></Row>
          <Text strong style={{ flex: 1, color: 'white' }}>
            生成日時
          </Text>
          <Text strong style={{ flex: 1, color: 'white' }}>
            種類
          </Text>
          <Text strong style={{ flex: 1, color: 'white' }}>
            モデル名
          </Text>
          <Text strong style={{ flex: 1, color: 'white' }}>
            画像説明内容
          </Text>
          <Row style={{ flex: 1 }}></Row>
        </Row>
        {mediaProcessingList.map((item: MediaProcessing) => (
          <>
            {(item?.ai_generate_type == Constant.mediaType.txt2Img ||
              item?.ai_generate_type == Constant.mediaType.img2Img) && (
              <ImageItem
                key={item.id}
                id={item.id}
                image=''
                time={null}
                type={item.ai_generate_type}
                rank={item.rank}
                isMediaProcessing={true}
                created_at={item?.created_at}
                startReRender={getMediasProcessing}
                model={item.model}
                description={getDescriptions(item.descriptions)}
                subType={item.ai_generate_sub_type}
              />
            )}
          </>
        ))}
        {mediaFilteredList.map((item: MediaItem) => (
          <ImageItem
            key={item?.id}
            id={item.id}
            image={item.url}
            time={item.created_at}
            type={item.type_generate_media}
            startReRender={getMediasProcessing}
            model={item.model_name}
            description={item.description}
            setMediaSelectedList={setMediaSelectedList}
            mediaSelectedList={mediaSelectedList}
            recreatingStatus={item?.recreating_status}
            typeImg2Img={item?.type_img2img}
          />
        ))}
        {!isSpinning && mediaList?.length <= 0 && mediaProcessingList?.length <= 0 && (
          <div style={{ textAlign: 'center', marginTop: 20 }}>まだ生成画像がありません。</div>
        )}
      </Col>
    </Layout>
  )
}

export default PhotoLibraryScreen
