import axios from 'axios'

export default class CommonAPIs {
  static baseURL = 'https://nft-dev.test-development.work'
  // static baseURL = 'https://snafty-staging.test-development.work'
  static access_token = localStorage.getItem('token')

  static endpoints = {
    login: this.baseURL + '/ai-img/login',
    generateImgFromDescription: this.baseURL + '/ai-img/creation/description',
    generateImgFromImg: this.baseURL + '/ai-img/creation/mask',
    checkpoint: this.baseURL + '/ai-img/checkpoints',
    getMedias: this.baseURL + '/ai-img/media-by-user',
    rerenderImgToImg: this.baseURL + '/ai-img/media-by-user',
    processingJob: this.baseURL + '/ai-img/proccessing-job',
    recreateMask: this.baseURL + '/ai-img/creation/mask-recreating',
    generateImg2ImgPersonAnime: this.baseURL + '/ai-img/creation/img2img-person-anime',
    mediaStatusJob: this.baseURL + '/ai-img/job-data',
    fixBackgroundImg2Img2: this.baseURL + '/ai-img/creation/img2img-fix-background',
    fixHandTxt2Img: this.baseURL + '/ai-img/creation/txt2img-fix-hand'
  }

  static headers = {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json'
  }

  static async login(email: string, password: string) {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json'
    }
    try {
      const formData = new FormData()
      formData.append('email', email)
      formData.append('password', password)
      let response = await axios.post(this.endpoints.login, formData, { headers })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async generateImgFromDescription(
    descriptionList: any,
    checkPointSelected: string,
    token: string | null,
    isClothesTriggerWords: number
  ) {
    const access_token = token ?? this.access_token

    const headers = {
      ...this.headers,
      Authorization: 'Bearer ' + access_token
    }

    const formData = new FormData()
    for (var i = 0; i < descriptionList.length; i++) {
      console.log(`descriptionList[${i}].des::`, descriptionList[i].des)
      formData.append(`descriptions[${i}]`, descriptionList[i].des)
    }

    formData.append('checkpoint', checkPointSelected)
    formData.append('is_clothe', '1')
    formData.append('isClothesTriggerWords', `${isClothesTriggerWords}`)

    try {
      let response = await axios.post(CommonAPIs.endpoints.generateImgFromDescription, formData, {
        headers: headers
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async generateImgFromImg(
    token: string | null,
    imageCropUrl: any,
    checkPointSelected: string,
    clothesTypeSelected: number,
    descriptionClothes: string
  ) {
    const access_token = token ?? this.access_token

    console.log('access_token::', access_token)
    console.log('imageCropUrl::', imageCropUrl)
    console.log('checkPointSelected::', checkPointSelected)
    console.log('clothesTypeSelected::', clothesTypeSelected)
    console.log('descriptionClothes::', descriptionClothes)

    const formData = new FormData()
    formData.append('image', imageCropUrl)
    formData.append('checkpoint', checkPointSelected)
    formData.append('clothes_type', String(clothesTypeSelected))
    if (descriptionClothes) {
      formData.append('description', descriptionClothes)
    }

    try {
      let response = await axios.post(CommonAPIs.endpoints.generateImgFromImg, formData, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + access_token
        }
      })
      return Promise.resolve(response.data?.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getCheckpointList(token: string | null) {
    const access_token = token ?? this.access_token
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${access_token}`
    }
    try {
      let response = await axios.get(CommonAPIs.endpoints.checkpoint, {
        headers
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getMedias(token: string | null) {
    const access_token = token ?? this.access_token
    try {
      let response = await axios.get(CommonAPIs.endpoints.getMedias, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + access_token
        }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getMediasProcessing(token: string | null) {
    const access_token = token ?? this.access_token
    console.log('Access token::', access_token)
    try {
      let response = await axios.get(CommonAPIs.endpoints.processingJob, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + access_token
        }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async rerenderMask(token: string | null, paintImg: any, mediaID: number | string) {
    const access_token = token ?? this.access_token

    const formData = new FormData()
    formData.append('mask_url', paintImg)

    try {
      const url = `${CommonAPIs.endpoints.recreateMask}/${mediaID}`
      let response = await axios.post(url, formData, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + access_token
        }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async generateImgFromImgPersonAnime(
    token: string | null,
    imageCropUrl: any,
    checkPointSelected: string,
    clothesTypeSelected: number,
    descriptionClothes: string
  ) {
    const access_token = token ?? this.access_token

    console.log('access_token::', access_token)
    console.log('imageCropUrl::', imageCropUrl)
    console.log('checkPointSelected::', checkPointSelected)
    console.log('clothesTypeSelected::', clothesTypeSelected)
    console.log('descriptionClothes::', descriptionClothes)

    const formData = new FormData()
    formData.append('image', imageCropUrl)
    formData.append('checkpoint', checkPointSelected)
    formData.append('clothes_type', String(clothesTypeSelected))
    if (descriptionClothes) {
      formData.append('description', descriptionClothes)
    }

    try {
      let response = await axios.post(CommonAPIs.endpoints.generateImg2ImgPersonAnime, formData, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + access_token
        }
      })
      return Promise.resolve(response.data?.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async checkStatusMediaJob(token: string | null, jobID: number) {
    const access_token = token ?? this.access_token
    try {
      const url = `${CommonAPIs.endpoints.mediaStatusJob}/${jobID}/status`
      let response = await axios.get(url, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + access_token
        }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async fixBackgroundImg2Img(token: string | null, paintImg: any, mediaID: number | string) {
    const access_token = token ?? this.access_token

    const formData = new FormData()
    formData.append('mask_url', paintImg)

    try {
      const url = `${CommonAPIs.endpoints.fixBackgroundImg2Img2}/${mediaID}`
      let response = await axios.post(url, formData, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + access_token
        }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async fixHandTxt2Img(token: string | null, paintImg: any, mediaID: number | string) {
    const access_token = token ?? this.access_token

    const formData = new FormData()
    formData.append('mask_url', paintImg)

    try {
      const url = `${CommonAPIs.endpoints.fixHandTxt2Img}/${mediaID}`
      console.log(url)
      let response = await axios.post(url, formData, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + access_token
        }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
