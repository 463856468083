import { Button, Col, Drawer, Flex, Row, Slider, Space } from 'antd'
import React, { useState, useRef, useContext, TouchEventHandler } from 'react'
import { AppContext } from '../../../hooks/AppContext'
import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import CommonAPIs from '../../../controller/API/CommonAPIs'

const InpaintFullScreen = () => {
  const { showWaitGenerate, showErrorAlert } = useContext(AppContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { srcProp, mediaID, isTxt2Img } = location.state ?? ''
  const canvasRef = useRef(null)
  const canvasDownloadRef = useRef(null)
  const [drawing, setDrawing] = useState(false)
  const [lineWidth, setLineWidth] = useState(22)

  console.log('isTxt2Img::', isTxt2Img)

  const access_token = localStorage.getItem('token') ?? null

  // const handleUpload = (event: any) => {
  //   canvasRef.current = null
  //   const file = event.target.files[0]
  //   const reader = new FileReader()

  //   reader.onload = () => {
  //     const img: any = new Image()
  //     img.onload = () => {
  //       console.log('img::', img.src)
  //       setImage(img)
  //     }
  //     img.src = reader.result
  //   }

  //   reader.readAsDataURL(file)
  // }

  const handleMouseDown = (event: any) => {
    setDrawing(true)
    const canvas: any = canvasRef.current
    const ctx = canvas.getContext('2d')
    // ctx.strokeStyle = "white"; // Set the stroke color to red
    // ctx.lineWidth = 20; // Increase the line width for a larger stroke
    // ctx.lineCap = "round"; // Set the line cap to round for a rounded stroke
    ctx.beginPath()
    ctx.moveTo(event.clientX, event.clientY)
  }

  const handleMouseMove = (event: any) => {
    if (!drawing) return
    const canvas: any = canvasRef.current
    const ctx = canvas.getContext('2d')
    ctx.lineTo(event.clientX, event.clientY)
    ctx.strokeStyle = 'white'
    ctx.lineWidth = lineWidth
    ctx.lineCap = 'round'
    ctx.lineJoin = 'round' // Set the line join to round for a rounded stroke
    ctx.stroke()
  }

  const handleMouseUp = () => {
    setDrawing(false)
  }

  const handleTouchDown = (event: any) => {
    console.log('event::', event)
    //get x and y position of the touch event
    const x = event.touches[0].clientX
    const y = event.touches[0].clientY
    setDrawing(true)
    const canvas: any = canvasRef.current
    const ctx = canvas.getContext('2d')
    // ctx.strokeStyle = "white"; // Set the stroke color to red
    // ctx.lineWidth = 20; // Increase the line width for a larger stroke
    // ctx.lineCap = "round"; // Set the line cap to round for a rounded stroke
    ctx.beginPath()
    ctx.moveTo(x, y)
  }

  const handleTouchMove = (event: any) => {
    if (!drawing) return
    //get x and y position of the touch event
    const x = event.touches[0].clientX
    const y = event.touches[0].clientY
    const canvas: any = canvasRef.current
    const ctx = canvas.getContext('2d')
    ctx.lineTo(x, y)
    ctx.strokeStyle = 'white'
    ctx.lineWidth = lineWidth
    ctx.lineCap = 'round'
    ctx.lineJoin = 'round' // Set the line join to round for a rounded stroke
    ctx.stroke()
  }

  const handleTouchUp = () => {
    setDrawing(false)
  }

  const onGoBack = () => {
    navigate(-1)
  }

  // const handleDownload = () => {
  //   const canvas: any = canvasRef.current
  //   const ctx = canvas.getContext('2d')

  //   // Create a copy of the canvas
  //   const canvasCopy = document.createElement('canvas')
  //   const ctxCopy: any = canvasCopy.getContext('2d')
  //   canvasCopy.width = canvas.width
  //   canvasCopy.height = canvas.height
  //   ctxCopy.drawImage(canvas, 0, 0)

  //   // Clear the canvas and display the original image
  //   ctx.globalCompositeOperation = 'destination-over'
  //   ctx.fillStyle = 'black'
  //   ctx.fillRect(0, 0, canvas.width, canvas.height)

  //   const link = document.createElement('a')
  //   link.href = canvas.toDataURL()
  //   link.download = 'modified_image.png'
  //   link.click()
  //   console.log('link::', link.href)

  //   // Restore the canvas from the copy
  //   ctx.clearRect(0, 0, canvas.width, canvas.height)
  //   ctx.drawImage(canvasCopy, 0, 0)
  // }

  const handleConvertBase64ToFile = (base64: string) => {
    const filename = 'image.jpg'
    const mimeType = 'image/jpeg'
    // Split the base64 string to get the data part
    const dataPart = base64.split(',')[1]

    // Convert the base64 data to a Blob
    const blob = atob(dataPart)
    const arrayBuffer = new ArrayBuffer(blob.length)
    const uint8Array = new Uint8Array(arrayBuffer)
    for (let i = 0; i < blob.length; i++) {
      uint8Array[i] = blob.charCodeAt(i)
    }

    // Create a Blob
    const blobObject = new Blob([uint8Array], { type: mimeType })

    // Create a File
    const file = new File([blobObject], filename, { type: mimeType })

    return file
  }

  const getBase64InPainImg = () => {
    const canvas: any = canvasRef.current
    const ctx = canvas.getContext('2d')

    // Create a copy of the canvas
    const canvasCopy = document.createElement('canvas')
    const ctxCopy: any = canvasCopy.getContext('2d')
    canvasCopy.width = canvas.width
    canvasCopy.height = canvas.height
    ctxCopy.drawImage(canvas, 0, 0)

    // Clear the canvas and display the original image
    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = 'black'
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    const link = document.createElement('a')
    link.href = canvas.toDataURL()
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(canvasCopy, 0, 0)
    return link.href
  }

  const handleReRenderHand = async () => {
    const base64 = getBase64InPainImg()
    let paintImg: any = ''
    if (base64) {
      paintImg = handleConvertBase64ToFile(base64)
    }
    if (paintImg) {
      console.log('fix hand img2img')
      await CommonAPIs.rerenderMask(access_token, paintImg, mediaID)
        .then((res) => {
          showWaitGenerate()
          setTimeout(() => {
            onGoBack()
          }, 800)
        })
        .catch((err) => showErrorAlert(err))
      // if (isTxt2Img) {
      //   console.log('fix hand txt2img')
      //   await CommonAPIs.fixHandTxt2Img(access_token, paintImg, mediaID)
      //     .then((res) => {
      //       showWaitGenerate()
      //       setTimeout(() => {
      //         onGoBack()
      //       }, 800)
      //     })
      //     .catch((err) => showErrorAlert(err))
      // } else {
      //   console.log('fix hand img2img')
      //   await CommonAPIs.rerenderMask(access_token, paintImg, mediaID)
      //     .then((res) => {
      //       showWaitGenerate()
      //       setTimeout(() => {
      //         onGoBack()
      //       }, 800)
      //     })
      //     .catch((err) => showErrorAlert(err))
      // }
    }
  }

  return (
    <Col>
      <Row>
        <Flex vertical align='center' style={{ marginTop: 5 }}>
          <div style={{ position: 'relative', width: 576, height: 768, aspectRatio: 3 / 4 }}>
            <img
              src={srcProp}
              alt=''
              style={{
                position: 'absolute',
                zIndex: 999
              }}
            />
            {srcProp && (
              <>
                <canvas
                  ref={canvasRef}
                  width={576}
                  height={768}
                  onTouchStart={handleTouchDown}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchUp}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  style={{
                    position: 'absolute',
                    zIndex: 999,
                    cursor: 'grab' // Change the cursor to a circle
                  }}
                />
              </>
            )}
          </div>
        </Flex>
        <Flex vertical style={{ marginLeft: 100 }}>
          {srcProp && (
            <Slider
              defaultValue={lineWidth}
              onChange={setLineWidth}
              max={50}
              min={5}
              style={{ width: 400, marginBottom: 30, marginTop: 20 }}
            />
          )}
          {/* <input type='file' accept='image/*' onChange={handleUpload} /> */}
          <Button
            type='primary'
            onClick={handleReRenderHand}
            style={{ marginRight: 20, alignItems: 'center', marginBottom: 30 }}
          >
            再生成する
          </Button>
          <Button
            type='primary'
            onClick={onGoBack}
            style={{ marginRight: 20, alignItems: 'center' }}
          >
            キャンセル
          </Button>
          {/* <Button onClick={toggleImageGenerationDrawer}>Render</Button> */}
        </Flex>
      </Row>
    </Col>
  )
}

export default InpaintFullScreen
