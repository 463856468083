import { Button, Col, Flex, Image, Row, Spin } from 'antd'
import React, { memo, useContext, useState } from 'react'

import { AppContext } from '../../../hooks/AppContext'
import InpaintScreen from '../../PhotoLibrary/components/InpaintScreen'
import { LoadingOutlined } from '@ant-design/icons'
import { GenerateImgType } from '../../../types'

type Props = {
  generatedImages: GenerateImgType[]
  mediaProcessingJobID: number
  setMediaProcessingJobID: any
  mediaSelectedInfo: any
  setMediaSelectedInfo: any
  isStopLoop: boolean
  isText2Img?: boolean
  isImg2Img: boolean
  isDisabled?: boolean
}

type Props2 = {
  item: GenerateImgType
  index: number
  setMediaProcessingJobID?: any
}

const GeneratedListItems = (props: Props) => {
  const {
    generatedImages,
    mediaProcessingJobID,
    setMediaProcessingJobID,
    mediaSelectedInfo,
    setMediaSelectedInfo,
    isStopLoop,
    isText2Img,
    isImg2Img = true,
    isDisabled
  } = props
  const { isMobile } = useContext(AppContext)

  const ImageItem = ({ item, index }: Props2) => {
    const [isVisibleInPaint, setIsVisibleInPaint] = useState<boolean>(false)

    const closeInPaint = () => {
      setIsVisibleInPaint(false)
    }
    const openInPaint = () => {
      setIsVisibleInPaint(true)
    }

    const isRenderHandProcessing = () => {
      if (mediaSelectedInfo?.id == item?.id) {
        return true
      }
      return false
    }

    return (
      <Flex vertical style={{ marginLeft: 20, marginRight: 20 }}>
        <Spin
          spinning={isRenderHandProcessing()}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
        >
          <Image
            width={110}
            height={150}
            src={item?.url}
            key={index}
            style={{ objectFit: 'contain' }}
          />
        </Spin>
        <Button
          className='render-hand-btn'
          style={{
            width: 105,
            marginTop: 25,
            marginBottom: 20
          }}
          onClick={openInPaint}
          disabled={isDisabled}
        >
          編集する
        </Button>
        <InpaintScreen
          isVisibleInPaint={isVisibleInPaint}
          closeInPaint={closeInPaint}
          mediaSelectedInfo={item}
          setMediaSelectedInfo={setMediaSelectedInfo}
          setMediaProcessingJobID={setMediaProcessingJobID}
          isImg2Img={isImg2Img}
        />
      </Flex>
    )
  }

  return (
    <>
      {(mediaProcessingJobID || generatedImages?.length > 0) && (
        <Flex
          vertical
          style={{
            marginLeft: isMobile ? 0 : 30,
            marginTop: -20,
            alignSelf: 'flex-start'
          }}
        >
          <h4>生成画像一覧</h4>
          {generatedImages?.length > 0 ? (
            <Col>
              <Row>
                {generatedImages?.map((item: GenerateImgType, index: number) => (
                  <Flex>
                    <ImageItem item={item} index={index} />
                  </Flex>
                ))}
              </Row>
            </Col>
          ) : (
            <div style={{ marginBottom: 30 }}>
              <Spin spinning={true} indicator={<LoadingOutlined style={{ fontSize: 24 }} />}>
                <div style={{ width: 110, height: 150, backgroundColor: 'gray' }} />
              </Spin>
            </div>
          )}
        </Flex>
      )}
    </>
  )
}

export default GeneratedListItems
