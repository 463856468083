import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Flex, Input, Button, Col, Row, Typography, Modal, Switch, Select, message } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { ItemType } from 'rc-menu/lib/interface'
import { useNavigate } from 'react-router'

import '../../controller/styles.css'
import CommonAPIs from '../../controller/API/CommonAPIs'
import { AppContext } from '../../hooks/AppContext'
import Util from '../../controller/Util'
import { CheckpointTypes, GenerateImgType } from '../../types'
import GeneratedListItems from './components/GeneratedListItems'

const { TextArea } = Input
const { Text } = Typography

const DESCRIPTION_LIMIT = 30

const boldStyle = {
  fontWeight: 700
}

const createNewEntry = () => ({})

const GenerateImgFromTextScreen = () => {
  const navigate = useNavigate()
  const access_token = localStorage.getItem('token') ?? null
  const {
    setMenuSelected,
    isMobile,
    showWaitGenerate,
    showErrorAlert,
    getEmailLimitedCheckpointStorage,
    checkDisableEmailLimited,
    checkEmailLimitedElements,
    accountLimit1,
    accountLimit2,
    accountLimit3
  } = useContext(AppContext)

  const [descriptionList, setDescriptionList] = useState<any>(() => [createNewEntry()])
  const [checkPointSelected, setCheckPointSelected] = useState<CheckpointTypes>({
    checkpoint: '',
    name: ''
  })
  const [checkPoints, setCheckPoints] = useState<CheckpointTypes[]>([])
  const [checkPointItems, setCheckPointItems] = useState<any>()
  const [isVisibleRemoveModal, setIsVisibleRemoveModal] = useState<boolean>(false)
  const [descriptionIndex, setDescriptionIndex] = useState<number>(0)
  const [isClothesTriggerWords, setIsClothesTriggerWords] = useState<boolean>(false)
  const [generatedImages, setGeneratedImages] = useState<GenerateImgType[]>([])
  const [mediaProcessingJobID, setMediaProcessingJobID] = useState<any>('')
  const [isStopLoop, setStopLoop] = useState(false)
  const [mediaSelectedInfo, setMediaSelectedInfo] = useState<GenerateImgType>()

  const checkIsDisabled = (): boolean => {
    if (mediaProcessingJobID) {
      return true
    }
    return false
  }

  const handleData = (index: number) => {
    setCheckPointSelected(checkPoints[index])
  }

  const handleChange = (e: any, index: any) => {
    const { name, value } = e.target

    setDescriptionList((descriptionList: any) => {
      const newDescription = [...descriptionList]
      newDescription[index] = { ...descriptionList[index], [name]: value }
      return newDescription
    })
  }

  const handleAddDescription = () => {
    if (descriptionList.length <= DESCRIPTION_LIMIT) {
      setDescriptionList([...descriptionList, createNewEntry()])
    }
  }

  const handleRemoveDescription = useCallback(() => {
    const newDt = [...descriptionList]
    newDt.splice(descriptionIndex, 1)
    setDescriptionList(newDt)
    setIsVisibleRemoveModal(false)
  }, [descriptionIndex, descriptionList])

  const onShowRemoveModal = (index: number) => {
    setIsVisibleRemoveModal(true)
    setDescriptionIndex(index)
  }

  const onHideRemoveModal = () => {
    setIsVisibleRemoveModal(false)
  }

  const handleGenerateImg = async () => {
    if (descriptionList[0]?.des) {
      setGeneratedImages([])
      setStopLoop(false)
      await CommonAPIs.generateImgFromDescription(
        descriptionList,
        checkPointSelected?.checkpoint,
        access_token,
        isClothesTriggerWords ? 1 : 0
      )
        .then((res) => {
          showWaitGenerate()
          setMediaProcessingJobID(res?.data?.job_id)
        })
        .catch((err: any) => Util.showErrorAlert(err))

      setTimeout(() => {
        setDescriptionList(() => [createNewEntry()])
      }, 500)
    }
  }

  const getCheckpoint = () => {
    CommonAPIs.getCheckpointList(access_token)
      .then((res) => {
        console.log('res::', res.data)

        setCheckPoints(res.data)
        setCheckPointSelected(res.data[0])
      })
      .catch((err: any) => {
        Util.showErrorAlert(err)
      })
  }

  const getMediasProcessing = () => {
    CommonAPIs.checkStatusMediaJob(access_token, mediaProcessingJobID)
      .then((res) => {
        console.log('res::', res)
        if (res?.status == 3 || res?.status == 2) {
          if (Object.values(res?.medias ?? [])?.length > 0 && mediaSelectedInfo) {
            const index = generatedImages.findIndex((e) => e?.url == mediaSelectedInfo?.url)
            const arr = [...generatedImages]
            arr[index] = res?.medias[0]
            console.log(arr)
            setGeneratedImages([...arr])
          } else {
            if (res?.status == 2) {
              message.error('画像の生成が失敗しました')
            } else {
              setGeneratedImages(res?.medias)
            }
          }
          setStopLoop(true)
          setMediaSelectedInfo(undefined)
          setMediaProcessingJobID('')
        }
      })
      .catch((err) => showErrorAlert(err))
  }

  useEffect(() => {
    if (mediaProcessingJobID) {
      const timerId = setInterval(() => {
        getMediasProcessing()
      }, 5000)

      if (isStopLoop) {
        clearInterval(timerId)
      }

      return () => {
        clearInterval(timerId)
      }
    }
  }, [mediaProcessingJobID, isStopLoop])

  useEffect(() => {
    setMenuSelected(1)

    // handle limit checkpoint for account
    if (getEmailLimitedCheckpointStorage() == accountLimit1) {
      setCheckPointSelected({
        checkpoint: 'BRA7_Faststep06v6.fp16',
        name: '星乃リア'
      })
    } else if (getEmailLimitedCheckpointStorage() == accountLimit2) {
      setCheckPointSelected({
        checkpoint: 'MargeYokosancharaV01.fp16.safetensors',
        name: 'ようこうさん'
      })
    } else if (getEmailLimitedCheckpointStorage() == accountLimit3) {
      const newCheckpointList = [
        {
          checkpoint: 'Marge-BRA6-4c-jewelry-RingMixLexicaV2.fp16.safetensors',
          name: 'CLA(リング)'
        },
        {
          checkpoint: 'BRA6MargeMix240402UnderwearV01.fp16.safetensors',
          name: '下着'
        },
        {
          checkpoint: 'BRA6MargeMix240402OfflineV01.fp16.safetensors',
          name: '服'
        },
        {
          checkpoint: 'MergeBRA6-gelatopique-hoodiev1.fp16.safetensors',
          name: '服_ジェラピケV1'
        },
        {
          checkpoint: 'MergeBRA6-gelatopique-hoodiev2.fp16.safetensors',
          name: '服_ジェラピケV2'
        }
      ]
      setCheckPoints(newCheckpointList)
      setCheckPointSelected(newCheckpointList[0])
    } else {
      getCheckpoint()
    }
  }, [])

  useEffect(() => {
    const items = checkPoints.map((item: any, index: number) => {
      const a: ItemType = {
        label: <p onClick={() => handleData(index)}>{item.name}</p>,
        key: index
      }
      return a
    })
    setCheckPointItems(items)
  }, [checkPoints])

  return (
    <Flex vertical>
      <h1 className='title'>テキスト入力で画像生成</h1>
      <Flex>
        <Col style={{ marginLeft: isMobile ? 0 : 40, marginTop: 20 }}>
          <Row style={{ alignItems: 'center', width: '100%' }}>
            <Typography style={{ width: 150 }}>モデル</Typography>
            <Select
              options={checkPointItems}
              style={{ minWidth: 200, marginLeft: 20, height: 36 }}
              value={checkPointSelected.name}
              disabled={checkIsDisabled() || checkDisableEmailLimited()}
            />
          </Row>
          {!checkEmailLimitedElements() && (
            <Row style={{ marginTop: 20 }}>
              <Typography style={{ width: 150, marginRight: isMobile ? 0 : 20 }}>
                服装カスタマイズ
              </Typography>
              <Switch
                value={isClothesTriggerWords}
                onChange={setIsClothesTriggerWords}
                disabled={checkIsDisabled()}
              />
            </Row>
          )}

          <Row style={{ marginTop: 20 }}>
            <Typography style={{ width: 150 }}>作成したい画像の説明</Typography>
            <Col>
              {descriptionList.map((item: any, index: number) => {
                return (
                  <Flex justify='flex-end' align='flex-end'>
                    <TextArea
                      name='des'
                      value={descriptionList[index].des}
                      className='input'
                      placeholder='生成したい要望を入力してください'
                      style={{
                        height: 185,
                        marginBottom: 20,
                        resize: 'none',
                        marginLeft: isMobile ? 0 : 20
                      }}
                      onChange={(event) => handleChange(event, index)}
                      disabled={checkIsDisabled()}
                    />
                    {descriptionList.length >= 2 && (
                      <div>
                        <MinusCircleOutlined
                          style={{
                            marginLeft: 8,
                            color: 'gray',
                            fontSize: 20,
                            paddingBottom: 20
                          }}
                          onClick={() => onShowRemoveModal(index)}
                        />
                        <Modal
                          title='通知'
                          open={isVisibleRemoveModal}
                          onCancel={onHideRemoveModal}
                          onOk={handleRemoveDescription}
                          okText='はい'
                          cancelText='いいえ'
                        >
                          <Flex>
                            <p>この説明内容を削除しますか？</p>
                          </Flex>
                        </Modal>
                      </div>
                    )}
                  </Flex>
                )
              })}
            </Col>
          </Row>
          {!checkEmailLimitedElements() && (
            <Col>
              <Typography>
                <Text style={boldStyle}>【カスタマイズ画像生成機能】</Text>
                <br />
                ボタンをONにすることで、より具体的に画像生成指示文章に基づいて
                <br />
                カスタマイズされた画像を生成することができます。
                <br />
                <Text style={boldStyle}>◉必要な情報</Text>
                <br />
                <Text style={boldStyle}>【服装】</Text>
                <br />
                画像に登場する人物の服装について、具体的に記載してください。
                <br />
                例：「青のデニムパンツ、白の無地Tシャツ、黒のダブルのレザージャケット」
                <br />
                <Text style={boldStyle}>【シチュエーション】</Text>
                <br />
                画像の中の人物が<Text style={boldStyle}>どんな場所で何をしているのか</Text>
                具体的に記載してください。
                <br />
                例：「都内、カフェ、昼食、秋」
                <br />
                <Text style={boldStyle}>【実際に入力する文章】</Text>
                <br />
                画像に反映させたい<Text style={boldStyle}>シナリオやストーリー</Text>
                を文章で記述してください。
                <br />
                例：季節は秋。今日は、友達と遊びに行く約束をしているので、カフェで食事をして待っている。
                <br />
                今日のファッションのポイントは、黒のダブルレザージャケットにデニムパンツに白の無地Tシャツで大人ぽさを演出。
              </Typography>
            </Col>
          )}

          <Flex style={{ marginTop: 25, marginBottom: 25, justifyContent: 'center' }}>
            <Button
              onClick={handleGenerateImg}
              type='primary'
              style={{ width: 103 }}
              disabled={checkIsDisabled()}
            >
              生成する
            </Button>
            <PlusCircleOutlined
              onClick={checkIsDisabled() ? undefined : handleAddDescription}
              size={40}
              style={{
                marginLeft: 30,
                color: checkIsDisabled() ? '#d9d9d9' : '#5E8CF4',
                fontSize: 25
              }}
              disabled={checkIsDisabled()}
            />
          </Flex>
        </Col>
      </Flex>
      <GeneratedListItems
        generatedImages={generatedImages}
        mediaProcessingJobID={mediaProcessingJobID}
        setMediaProcessingJobID={(job: any) => {
          setStopLoop(false)
          setMediaProcessingJobID(job)
        }}
        mediaSelectedInfo={mediaSelectedInfo}
        setMediaSelectedInfo={setMediaSelectedInfo}
        isStopLoop={isStopLoop}
        isText2Img={true}
        isImg2Img={false}
        isDisabled={checkIsDisabled()}
      />
    </Flex>
  )
}

export default GenerateImgFromTextScreen
