import { createBrowserRouter } from 'react-router-dom'
import BaseLayout from '../pages/layouts/BaseLayout'
import { SetupUser, Img2Img, Text2Image } from '../components'
import GenerateImgFromImgScreen from '../components/GenerateImg/GenerateImgFromImgScreen'
import GenerateImgFromTextScreen from '../components/GenerateImg/GenerateImgFromTextScreen'
import LoginScreen from '../components/Auth/LoginScreen'
import PhotoLibraryScreen from '../components/PhotoLibrary/PhotoLibraryScreen'
import ProfileScreen from '../components/GenerateImg/ProfileScreen'
import InpaintScreen from '../components/PhotoLibrary/components/InpaintScreen'
import GenerateImg2ImgShortFlowScreen from '../components/GenerateImg/GenerateImg2ImgShortFlowScreen'
import InpaintFullScreen from '../components/PhotoLibrary/components/InpaintFullScreen'

const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginScreen />
  },
  {
    path: 'inpaint',
    element: <InpaintFullScreen />
  },
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <GenerateImgFromTextScreen />
      },
      {
        path: 'txt2img',
        element: <GenerateImgFromTextScreen />
      },
      {
        path: 'img2img',
        element: <GenerateImgFromImgScreen />
      },
      {
        path: 'photo-library',
        element: <PhotoLibraryScreen />
      },
      {
        path: 'profile',
        element: <ProfileScreen />
      },
      {
        path: 'img2img-person-anime',
        element: <GenerateImg2ImgShortFlowScreen />
      }
    ]
  }
])

export default router
