import { UploadOutlined } from '@ant-design/icons'
import { Upload, Flex } from 'antd'
import ImgCrop from 'antd-img-crop'
import { useEffect, useState } from 'react'
import { RcFile } from 'antd/es/upload'

type Props = {
  imageCropUrl: string | undefined
  setImageCropUrl: (value: string) => void
  imageCropUrlBase64: string | undefined
  setImageCropUrlBase64: (value: string) => void
  isDisabled?: boolean
}

const UploadImageDragger = (props: Props) => {
  const { setImageCropUrl, imageCropUrlBase64, setImageCropUrlBase64, isDisabled } = props
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [heightUpload, setHeightUpload] = useState<number>(380)
  const [widthUpload, setWidthUpload] = useState<number>(450)
  const [cropAspect, setCropAspect] = useState<number>(0)
  const [outImgWidth, setOutImgWidth] = useState<number>(0)
  const [outImgHeight, setOutImgHeight] = useState<number>(0)

  const uploadButton = (
    <Flex vertical justify='center' align='center'>
      <UploadOutlined style={{ fontSize: 30, color: '#6B7280' }} />
      <p
        style={{
          display: 'flex',
          marginTop: 8,
          textAlign: 'center',
          color: '#6B7280'
        }}
      >
        ここに画像をドロップ
        <br />
        -または-
        <br />
        クリックしてアップロード
      </p>
    </Flex>
  )

  const beforeCrop = (file: RcFile) => {
    return new Promise<void>((resolve, reject) => {
      const image = new Image()
      image.src = URL.createObjectURL(file)
      image.onload = () => {
        const width = image.width
        const height = image.height

        let outImgWidth = width
        let outImgHeight = height
        if (width < height) {
          if (height >= 768) {
            outImgWidth = 576
            outImgHeight = 768
          }
        } else if (width > height) {
          if (width > 768) {
            outImgWidth = 768
            outImgHeight = 576
          }
        } else {
          if (width > 768) {
            outImgWidth = 768
            outImgHeight = 768
          }
        }

        setOutImgWidth(outImgWidth)
        setOutImgHeight(outImgHeight)
        const aspect = outImgWidth / outImgHeight
        setCropAspect(aspect)
        resolve()
      }
    })
  }

  const onChange = (info: any) => {
    console.log('info::', info.file)
    const croppedImage = info.file.originFileObj
    // setImageCropUrl(croppedImage)

    // crop image to 576x768
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const image = new Image()
    image.src = URL.createObjectURL(croppedImage)
    image.onload = () => {
      canvas.width = outImgWidth
      canvas.height = outImgHeight
      ctx?.drawImage(image, 0, 0, outImgWidth, outImgHeight)
      const dataUrl = canvas.toDataURL('image/jpeg')

      //dataUrl to base64
      const base64String: any = dataUrl
      setImageCropUrlBase64(base64String)
      console.log('====================================')
      console.log('base64String::', base64String)
      console.log('====================================')

      // base64 to file
      const dataURItoBlob = (dataURI: any) => {
        const byteString = atob(dataURI.split(',')[1])
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        const arrayBuffer = new ArrayBuffer(byteString.length)
        const uint8Array = new Uint8Array(arrayBuffer)

        for (let i = 0; i < byteString.length; i++) {
          uint8Array[i] = byteString.charCodeAt(i)
        }

        return new Blob([arrayBuffer], { type: mimeString })
      }

      // Convert Blob to File
      const blobToFile = (blob: any, fileName: any) => {
        return new File([blob], fileName, { type: blob.type })
      }

      // Convert base64 to File
      const base64ToFile = (base64String: any, fileName: any) => {
        const blob = dataURItoBlob(base64String)
        return blobToFile(blob, fileName)
      }

      // Usage
      const fileName = 'image.png' // Specify the desired file name
      const localFile: any = base64ToFile(base64String, fileName)
      console.log('====================================')
      console.log('localFile::', localFile)
      console.log('====================================')
      setImageCropUrl(localFile)
      if (localFile) {
        const reader = new FileReader()

        reader.onload = (e: any) => {
          const image: any = new Image()

          image.onload = () => {
            const width = image.width
            const height = image.height

            console.log(`Width: ${width}`)
            console.log(`Height: ${height}`)
          }

          image.src = e.target.result
        }

        reader.readAsDataURL(localFile)
      }
    }

    // Use FileReader to convert the cropped image to base64
    // const reader = new FileReader()

    // reader.onloadend = () => {
    //   // The result property contains the base64-encoded string
    //   const base64String: any = reader.result
    //   // setImageCropUrlBase64(base64String)
    //   // console.log('Base64 String:', base64String)

    //   // Now you can use the base64String as needed, for example, send it to the server.
    // }

    // // Start reading the file as a data URL (base64)
    // reader.readAsDataURL(croppedImage)
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setHeightUpload((380 * windowWidth) / 750)
    setWidthUpload((450 * windowWidth) / 750)
  }, [windowWidth])

  return (
    <Flex
      vertical
      style={{
        height: heightUpload,
        width: widthUpload,
        maxHeight: 440,
        maxWidth: 570,
        padding: 12,
        borderStyle: 'dashed',
        borderWidth: 0.5,
        borderRadius: 10,
        borderColor: '#6B7280',
        marginTop: 40
      }}
      justify='center'
      align='center'
    >
      <ImgCrop showGrid rotationSlider showReset aspect={cropAspect} beforeCrop={beforeCrop}>
        <Upload
          listType='picture'
          onChange={onChange}
          name='avatar'
          className='avatar-uploader'
          showUploadList={false}
          disabled={isDisabled}
        >
          <Flex justify='center' align='center'>
            {imageCropUrlBase64 ? (
              <img
                src={imageCropUrlBase64}
                alt='avatar'
                style={{ width: 'auto', height: heightUpload * 0.9, maxHeight: 410, maxWidth: 550 }}
              />
            ) : (
              uploadButton
            )}
          </Flex>
        </Upload>
      </ImgCrop>
    </Flex>
  )
}

export default UploadImageDragger
