import { Flex, Button, Typography, Row, Col, Select, message, Input } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import UploadImageDragger from './components/UploadImageDragger'
import CommonAPIs from '../../controller/API/CommonAPIs'
import { AppContext } from '../../hooks/AppContext'
import Util from '../../controller/Util'
import { CheckpointTypes, GenerateImgType } from '../../types'
import GeneratedListItems from './components/GeneratedListItems'

type ClothesType = {
  type: number
  label: any
}

const clothesOptionData = [
  {
    type: 1,
    label: 'トリガーワードデフォルト'
  },
  {
    type: 2,
    label: '服装を指定できる'
  },
  {
    type: 3,
    label: 'タガーで取れた服装を自動入れる'
  }
]

const GenerateImgFromImgScreen = () => {
  const token = localStorage.getItem('token') ?? null
  const {
    setMenuSelected,
    showWaitGenerate,
    showErrorAlert,
    getEmailLimitedCheckpointStorage,
    checkDisableEmailLimited,
    accountLimit1,
    accountLimit2
  } = useContext(AppContext)

  const [imageCropUrl, setImageCropUrl] = useState<string>()
  const [imageCropUrlBase64, setImageCropUrlBase64] = useState<string>()
  const [checkPointSelected, setCheckPointSelected] = useState<CheckpointTypes>({
    checkpoint: '',
    name: ''
  })
  const [checkPoints, setCheckPoints] = useState<CheckpointTypes[]>([])
  const [generatedImages, setGeneratedImages] = useState<GenerateImgType[]>([])
  const [mediaProcessingJobID, setMediaProcessingJobID] = useState<any>('')
  const [isStopLoop, setStopLoop] = useState(false)
  const [mediaSelectedInfo, setMediaSelectedInfo] = useState<GenerateImgType>()
  const [clothesOption, setClothesOption] = useState<ClothesType[]>(clothesOptionData)
  const [clothesOptionSelected, setClothesOptionSelected] = useState<ClothesType>({
    type: 1,
    label: 'トリガーワードデフォルト'
  })
  const [descriptionClothes, setDescriptionClothes] = useState('')

  const checkIsDisabled = (): boolean => {
    if (mediaProcessingJobID) {
      return true
    }
    return false
  }

  const handleGenerateImg = () => {
    if (!imageCropUrl) return

    if (clothesOptionSelected.type == 2 && descriptionClothes == '') return

    setGeneratedImages([])
    setStopLoop(false)
    CommonAPIs.generateImgFromImg(
      token,
      imageCropUrl,
      checkPointSelected?.checkpoint,
      clothesOptionSelected?.type,
      descriptionClothes
    )
      .then((res) => {
        showWaitGenerate()
        console.log('ress::', res)
        setMediaProcessingJobID(res?.job_id)
      })
      .catch((err: any) => Util.showErrorAlert(err))
  }

  const getCheckpoint = () => {
    CommonAPIs.getCheckpointList(token)
      .then((res) => {
        console.log('res::', res)
        setCheckPoints(res.data)
        setCheckPointSelected(res.data[0])
      })
      .catch((err: any) => {
        Util.showErrorAlert(err)
      })
  }

  const getMediasProcessing = () => {
    CommonAPIs.checkStatusMediaJob(token, mediaProcessingJobID)
      .then((res) => {
        console.log('res::', res)
        if (res?.status == 3 || res?.status == 2) {
          if (res?.status == 2) {
            message.error('画像の生成が失敗しました')
          } else {
            setGeneratedImages(res?.medias)
          }
          setStopLoop(true)
          setMediaSelectedInfo(undefined)
          setMediaProcessingJobID('')
        }
      })
      .catch((err) => showErrorAlert(err))
  }

  const handleSelectCheckpoint = (value: any, selected: any) => {
    console.log('selected::', selected)
    setCheckPointSelected({
      checkpoint: selected?.checkpoint,
      name: selected?.value
    })
  }

  const handleSelectClothes = (value: any, selected: any) => {
    console.log('selected::', selected)
    setClothesOptionSelected({
      type: selected?.type,
      label: selected?.value
    })
  }

  useEffect(() => {
    setMenuSelected(2)

    // handle limit checkpoint for account
    if (getEmailLimitedCheckpointStorage() == accountLimit1) {
      setCheckPointSelected({
        checkpoint: 'BRA7_Faststep06v6.fp16',
        name: '星乃リア'
      })
    } else if (getEmailLimitedCheckpointStorage() == accountLimit2) {
      setCheckPointSelected({
        checkpoint: 'MargeYokosancharaV01.fp16.safetensors',
        name: 'ようこうさん'
      })
    } else {
      getCheckpoint()
    }
  }, [])

  useEffect(() => {
    if (mediaProcessingJobID) {
      const timerId = setInterval(() => {
        getMediasProcessing()
      }, 5000)

      if (isStopLoop) {
        clearInterval(timerId)
      }

      return () => {
        clearInterval(timerId)
      }
    }
  }, [mediaProcessingJobID, isStopLoop])

  return (
    <>
      <h1 className='title'>背景・人物の全体イラスト化</h1>
      <Flex vertical justify='center' align='center'>
        <Flex vertical justify='center' align='center'>
          <UploadImageDragger
            imageCropUrl={imageCropUrl}
            setImageCropUrl={setImageCropUrl}
            imageCropUrlBase64={imageCropUrlBase64}
            setImageCropUrlBase64={setImageCropUrlBase64}
            isDisabled={checkIsDisabled()}
          />
          <Col style={{ marginTop: 20, alignSelf: 'flex-start', marginLeft: 15 }}>
            <Row
              style={{
                alignItems: 'center',
                marginBottom: 10,
                justifyContent: 'space-between'
              }}
            >
              <Typography>モデル</Typography>
              <Select
                style={{ minWidth: 250, marginLeft: 20, height: 36 }}
                disabled={checkIsDisabled() || checkDisableEmailLimited()}
                value={checkPointSelected?.name}
                onChange={handleSelectCheckpoint}
              >
                {checkPoints?.map((item: CheckpointTypes) => (
                  <Select.Option key={item?.name} value={item.name} checkpoint={item.checkpoint}>
                    <p>{item?.name}</p>
                  </Select.Option>
                ))}
              </Select>
            </Row>
            <Row
              style={{
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 10
              }}
            >
              <Typography>服装カスタマイズ</Typography>
              <Select
                style={{
                  minWidth: 250,
                  marginLeft: 20,
                  height: 36
                }}
                disabled={checkIsDisabled()}
                value={clothesOptionSelected?.label}
                onChange={handleSelectClothes}
              >
                {clothesOption?.map((item: ClothesType) => (
                  <Select.Option key={item.type} value={item.label} type={item.type}>
                    <p>{item.label}</p>
                  </Select.Option>
                ))}
              </Select>
            </Row>
            {clothesOptionSelected?.type == 2 && (
              <Row
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}
              >
                <Input.TextArea
                  value={descriptionClothes}
                  placeholder='服装を指定してください'
                  style={{
                    height: 100,
                    marginBottom: 20,
                    resize: 'none',
                    width: 250
                  }}
                  onChange={(e: any) => setDescriptionClothes(e.target?.value)}
                  disabled={checkIsDisabled()}
                />
              </Row>
            )}
          </Col>
          <Button
            onClick={handleGenerateImg}
            type='primary'
            style={{ width: 105, marginTop: 30, marginBottom: 30 }}
            disabled={checkIsDisabled()}
          >
            生成する
          </Button>
        </Flex>
        <GeneratedListItems
          generatedImages={generatedImages}
          mediaProcessingJobID={mediaProcessingJobID}
          setMediaProcessingJobID={(job: any) => {
            setStopLoop(false)
            setMediaProcessingJobID(job)
          }}
          mediaSelectedInfo={mediaSelectedInfo}
          setMediaSelectedInfo={setMediaSelectedInfo}
          isStopLoop={isStopLoop}
          isImg2Img={true}
          isDisabled={checkIsDisabled()}
        />
      </Flex>
    </>
  )
}

export default GenerateImgFromImgScreen
