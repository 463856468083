import { Progress, Spin } from 'antd'
import { RouterProvider } from 'react-router-dom'
import { useContext, useEffect } from 'react'

import './App.css'
import { AppContext } from './hooks/AppContext'
import router from './router'
import CommonAPIs from './controller/API/CommonAPIs'

const conicColors = { '0%': '#87d068', '50%': '#ffe58f', '100%': '#ffccc7' }

function App() {
  const { isSpinning, contextHolder, progress, screenName, showGenerateCompleted, showErrorAlert } =
    useContext(AppContext)
  const token = localStorage.getItem('token')

  const getMediasProcessing = () => {
    if (token == null || token == undefined || token == '') {
      return
    }
    const mediaProcessingStorageAmount = localStorage.getItem('mediaProcessingCoverAmount') ?? 0
    CommonAPIs.getMediasProcessing(token)
      .then((res) => {
        if (mediaProcessingStorageAmount && Number(mediaProcessingStorageAmount) > res?.length) {
          //Handle successed
          showGenerateCompleted()
        }
        localStorage.setItem('mediaProcessingCoverAmount', res?.length)
      })
      .catch((error) => showErrorAlert(error))
  }

  const getTypeProgress = () => {
    if (screenName) {
      return <Progress type='circle' percent={progress} strokeColor={conicColors} size={70} />
    }
    return undefined
  }

  useEffect(() => {
    const timerId = setInterval(() => {
      getMediasProcessing()
    }, 15000)

    return () => {
      console.log('clearrrr')
      clearInterval(timerId)
    }
  }, [])

  return (
    <Spin spinning={isSpinning} size='large' className='spinner' indicator={getTypeProgress()}>
      <div style={{ height: window.innerHeight }}>
        {contextHolder}
        <RouterProvider router={router} />
      </div>
    </Spin>
  )
}

export default App
