export default {
  color: {},

  nameScreen: {
    txt2img: 'txt2img',
    img2img: 'img2img',
    photoLibrary: 'photoLibrary'
  },

  ICONS: {},
  image: {},
  renderType: {
    hand: 'hand',
    background: 'background'
  },
  mediaType: {
    AI: 2,
    normal: 1,
    txt2Img: 3,
    img2Img: 4,
    fixHandTxt2Img: 5,
    fixHandImg2Img: 6,
    fixBGImg2Img: 6
  },
  emailLimitCheckpoint: {
    account1: 'ria@gmail.com',
    account2: 'yokosan@gmail.com',
    account3: 'accessories@gmail.com'
  }
}
