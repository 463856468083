import React, { useContext, useEffect, useState } from 'react'
import { Input, Flex, Button } from 'antd'
import '../../controller/styles.css'
import logo from '../../logo.svg'
import { useNavigate } from 'react-router-dom'
import CommonAPIs from '../../controller/API/CommonAPIs'
import { AppContext } from '../../hooks/AppContext'

const LoginScreen = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const { setIsSpinning, showErrorAlert } = useContext(AppContext)

  const showHomeScreen = () => {
    setIsSpinning(true)
    CommonAPIs.login(email, password)
      .then((res) => {
        navigate('/txt2img')
        localStorage.setItem('token', res.data?.access_token)
        localStorage.setItem('accountLimitedCheckpoint', email)
      })
      .catch((err: any) => {
        showErrorAlert(err)
      })
      .finally(() => setIsSpinning(false))
  }

  useEffect(() => {
    const access_token = localStorage.getItem('token')
    console.log('access_token::', access_token)
    if (access_token) {
      navigate('/')
    }
  }, [])

  return (
    <Flex vertical justify='center' align='center'>
      <img className='logo-icon' width={220} src={logo} />
      <Flex vertical>
        <p>メールアドレス</p>
        <Input
          onChange={(text: any) => setEmail(text?.target?.value)}
          style={{ width: 410 * (window.innerWidth / 625), marginLeft: 30, maxWidth: 410 }}
        />
        <p>パスワード</p>
        <Input.Password
          type='password'
          onChange={(text: any) => setPassword(text?.target?.value)}
          style={{ width: 410 * (window.innerWidth / 625), marginLeft: 30, maxWidth: 410 }}
        />
      </Flex>
      <Button onClick={showHomeScreen} className='btn-login'>
        ログイン
      </Button>
    </Flex>
  )
}

export default LoginScreen
