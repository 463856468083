import React, { useContext, useEffect, useState } from 'react'
import { UserOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Breadcrumb, Layout, Menu, Spin, theme } from 'antd'
import { Outlet, useNavigate, useLocation, Navigate } from 'react-router-dom'
import { AppContext } from '../../hooks/AppContext'

const { Content, Sider } = Layout

const SideBarMenu = [
  {
    label: 'テキスト入力で画像生成',
    key: 1,
    icon: UserOutlined,
    route: 'txt2img'
  },
  {
    label: '背景・人物の全体イラスト化',
    key: 2,
    icon: UserOutlined,
    route: 'img2img'
  },
  {
    label: '人物のみのイラスト化',
    key: 6,
    icon: UserOutlined,
    route: 'img2img-person-anime'
  },
  {
    label: '画像生成履歴一覧',
    key: 3,
    icon: UserOutlined,
    route: 'photo-library'
  },
  // {
  //   label: 'プロフィール情報設定',
  //   key: 4,
  //   icon: UserOutlined,
  //   route: 'profile'
  // },
  {
    label: 'ログアウト',
    key: 5,
    icon: UserOutlined,
    route: 'login'
  }
]

const BaseLayout = (props: any) => {
  const {
    token: { colorBgContainer, borderRadiusLG }
  } = theme.useToken()
  const navigate = useNavigate()

  const location = useLocation()
  const { menuSelected, setMenuSelected, isMobile, checkEmailLimitedElements } =
    useContext(AppContext)

  const checkSideBarMenu = () => {
    if (checkEmailLimitedElements()) {
      return SideBarMenu.filter((element) => element.key !== 2 && element.key !== 6)
    }
    return SideBarMenu
  }

  const items2: MenuProps['items'] = checkSideBarMenu().map((e) => {
    // const key = String(index + 1);

    return {
      key: `${e.key}`,
      // icon: React.createElement(e.icon),
      label: `${e.label}`,
      onClick: () => {
        if (e.route == 'login') {
          localStorage.clear()
        }
        navigate(e.route)
      }

      // children: new Array(4).fill(null).map((_, j) => {
      //     const subKey = index * 4 + j + 1;
      //     return {
      //         key: subKey,
      //         label: `option${subKey}`,
      //     };
      // }),
    }
  })

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login')
    }
  }, [])

  return (
    <Layout>
      <Layout>
        {!location.pathname.includes('login') && (
          <Sider
            width={230}
            style={{ background: colorBgContainer }}
            // collapsible
            collapsed={isMobile}
            collapsedWidth={isMobile ? 40 : undefined}
          >
            <Menu
              mode='inline'
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              style={{ height: '100%', borderRight: 0 }}
              items={items2}
              selectedKeys={[`${menuSelected}`]}
              onClick={(item) => {
                if (item.key == '5') {
                  localStorage.clear()
                }
                setMenuSelected(Number(item.key))
              }}
            />
          </Sider>
        )}
        <Layout style={{ padding: '0 24px 24px', minHeight: window.innerHeight }}>
          <Breadcrumb>
            {/* <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>List</Breadcrumb.Item>
                        <Breadcrumb.Item>App</Breadcrumb.Item> */}
            <h3></h3>
          </Breadcrumb>
          <Content
            style={{
              paddingLeft: 15,
              paddingRight: 10,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              height: '100%'
            }}
          >
            {localStorage.getItem('token') ? <Outlet /> : <Navigate to='/login' replace />}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default BaseLayout
