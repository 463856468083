import { Button, Image, Row, Typography, Spin } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { LoadingOutlined } from '@ant-design/icons'
import { GenerateImgType } from '../../../types'
import InpaintScreen from './InpaintScreen'
import Constant from '../../../controller/Constant'

type Props = {
  id?: number
  image?: any
  time?: number | null
  type?: number
  rank?: number
  isMediaProcessing?: boolean
  created_at?: any
  startReRender: () => void
  model?: string
  description?: string
  setMediaSelectedList?: any
  mediaSelectedList?: GenerateImgType[]
  recreatingStatus?: boolean
  typeImg2Img?: number
  subType?: number
}

type Props2 = {
  src: string
  mediaSelectedList?: GenerateImgType[]
  isRenderHandProcessing: boolean
}

const MediaType = {
  text2imgRerenderHand: { TYPE: 5, LABEL: '再生成' },
  text2img: { TYPE: 3, LABEL: 'テキストで生成' },
  img2img: { TYPE: 4, LABEL: '背景・人物の全体イラスト化' },
  rerenderMask: { TYPE: 6, LABEL: '再生成' }
}

const Img2ImgType = {
  mainFlow: { TYPE: 1, LABEL: '背景・人物の全体イラスト化' },
  person2anime: { TYPE: 5, LABEL: '人物のみのイラスト化' }
}

const MAX_PERCENT = 90

const CustomImageLoader = ({ isRenderHandProcessing, src, mediaSelectedList }: Props2) => {
  const [loading, setLoading] = useState(true)

  const handleImageLoad = () => {
    setLoading(false)
  }

  return (
    <div style={{ position: 'relative', marginTop: 3 }}>
      {src ? (
        <Spin
          spinning={isRenderHandProcessing}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Image
            src={src}
            height={100}
            onLoad={handleImageLoad}
            style={{
              display: loading ? 'none' : 'flex',
              height: 100,
              aspectRatio: 3 / 4,
              objectFit: 'contain',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          />
        </Spin>
      ) : (
        <Spin spinning={true} indicator={<LoadingOutlined style={{ fontSize: 24 }} />}>
          <div
            style={{ height: 100, aspectRatio: 3 / 4, backgroundColor: '#D9D9D9', marginBottom: 3 }}
          />
        </Spin>
      )}
    </div>
  )
}

const ImageItem = (props: Props) => {
  const [progress, setProgress] = useState<number>(0)
  const { isMediaProcessing = false, setMediaSelectedList, mediaSelectedList , subType} = props
  const [isVisibleInPaint, setIsVisibleInPaint] = useState<boolean>(false)

  const closeInPaint = () => {
    setIsVisibleInPaint(false)
  }
  const openInPaint = () => {
    setIsVisibleInPaint(true)
  }
  const checkIsRenderHandProcessing = (): boolean => {
    const mediaIDArray: any = mediaSelectedList?.map((item) => item?.id)
    if (mediaIDArray?.includes(props?.id) || props?.recreatingStatus) {
      return true
    }
    return false
  }

  const getTypeMedia = () => {
    if (props?.type == MediaType.img2img.TYPE) {
      if (props?.typeImg2Img == Img2ImgType.person2anime.TYPE || 
        (subType && subType == Img2ImgType.person2anime.TYPE )) {
        return Img2ImgType.person2anime.LABEL
      } else {
        return MediaType.img2img.LABEL
      }
    }

    switch (props?.type) {
      case MediaType.text2img.TYPE:
        return MediaType.text2img.LABEL
      case MediaType.text2imgRerenderHand.TYPE:
        return MediaType.text2imgRerenderHand.LABEL
      case MediaType.rerenderMask.TYPE:
        return MediaType.rerenderMask.LABEL
      default:
        return ''
    }
  }

  useEffect(() => {
    if (isMediaProcessing) {
      const currentTime = moment()

      const elapsedTime = moment.duration(currentTime.diff(props.created_at))

      const totalTime = moment.duration(4 * Number(props?.rank), 'minutes')

      let percentage: any =
        (elapsedTime.asMilliseconds() / totalTime.asMilliseconds()) * MAX_PERCENT
      if (parseInt(percentage) >= MAX_PERCENT) {
        percentage = MAX_PERCENT
      }
      setProgress(parseInt(percentage))
    }
  }, [])

  useEffect(() => {
    if (isMediaProcessing) {
      const intervalId = setInterval(() => {
        setProgress((prevProgress: number) => {
          let newProgress = prevProgress >= MAX_PERCENT ? MAX_PERCENT : prevProgress + 1
          return newProgress
        })
      }, (4 * Number(props?.rank) * 60 * 1000) / MAX_PERCENT)

      if (progress == MAX_PERCENT) {
        setProgress(MAX_PERCENT)
        clearInterval(intervalId)
      }
      return () => {
        setProgress(0)
        clearInterval(intervalId)
      }
    }
  }, [])

  return (
    <>
      <Row
        style={{
          background: 'white',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <Row style={{ flex: 1, justifyContent: 'center' }}>
          <CustomImageLoader
            isRenderHandProcessing={checkIsRenderHandProcessing()}
            src={props?.image}
            mediaSelectedList={mediaSelectedList}
          />
        </Row>
        <Typography style={{ flex: 1, marginLeft: 10, whiteSpace: 'pre-line' }}>
          {props.time == null
            ? `生成中\n(${progress}%)`
            : moment(props.time).format('yyyy/MM/DD HH:mm')}
        </Typography>
        {/* <Typography style={{ flex: 1 }}>{props?.description}</Typography> */}
        <Typography style={{ flex: 1 }}>{getTypeMedia()}</Typography>
        <Typography style={{ flex: 1 }}>{props.model ?? ''}</Typography>
        <Typography
          style={{
            flex: 1,
            textAlign: 'center',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden'
          }}
        >
          {props.description ?? ''}
        </Typography>
        <Row style={{ flex: 1, justifyContent: 'center' }}>
          {props?.image && (
            <Button
              type='primary'
              onClick={openInPaint}
              disabled={checkIsRenderHandProcessing()}
              style={{ backgroundColor: checkIsRenderHandProcessing() ? undefined : '#4dcd80' }}
            >
              編集する
            </Button>
          )}
        </Row>
      </Row>
      <Row
        style={{
          height: 1,
          background: '#5AAAFF'
        }}
      />
      <InpaintScreen
        isVisibleInPaint={isVisibleInPaint}
        closeInPaint={closeInPaint}
        mediaSelectedInfo={{ id: props.id ?? 0, url: props.image }}
        setMediaProcessingJobID={() => {
          props.startReRender()
        }}
        isImg2Img={[
          Constant.mediaType.img2Img,
          Constant.mediaType.fixHandImg2Img,
          Constant.mediaType.fixBGImg2Img
        ].includes(props.type as number)}
        mediaSelectedList={mediaSelectedList}
        setMediaSelectedList={setMediaSelectedList}
        isMediaListScreen={true}
      />
    </>
  )
}

export default ImageItem
